<template>
<div>
  <v-row>
    <v-col cols="3">
      <v-card class="mt-0">
        <v-toolbar color="indigo" dark>
          <v-toolbar-title>Search Terms</v-toolbar-title>
        </v-toolbar>
      </v-card>
    </v-col>
    <v-col cols="9">
      <l-map v-if="searchTerms.length > 0" :zoom="zoom" :minZoom="minZoom" z-index="1" :center="center" style="height: 900px; width: 100%">
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <l-circle-marker v-for="searchTerms in searchTerms" :key="searchTerms.ID" :maxWidth="markrMaxHeight" :lat-lng="latLng(searchTerms.latitude, searchTerms.longitude)" :radius="circle.radius" :color="circle.color" :fillColor="circle.color" :weight="circle.weight">
          <l-popup>
            <v-list dense>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ searchTerms.areaName }}</v-list-item-title>
                  <v-list-item-subtitle v-for="terms in searchTerms.terms" :key="terms.termID">{{ terms.term }}({{ terms.numberOfSearches }}),</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list dense>
              <v-list-item>
                <span class="font-weight-bold">Outcode:</span> {{ searchTerms.outcode }}
              </v-list-item>
              <v-list-item>
                <span class="font-weight-bold">Contribution:</span>
                {{ searchTerms.overallContribution }}
              </v-list-item>
              <v-list-item>
                <span class="font-weight-bold">Area Searches:</span>
                {{ searchTerms.numberOfSearches }}
              </v-list-item>
               <v-list-item>
                <span class="font-weight-bold">HouseHolds:</span>
                {{ searchTerms.houseHolds }}
              </v-list-item>
            </v-list>
          </l-popup>
        </l-circle-marker>
      </l-map>
      <v-alert v-else type="warning">
        No Map results for this search
      </v-alert>
    </v-col>
  </v-row>
</div>
</template>

<script>
import L from 'leaflet'
import {
  LMap,
  LTileLayer,
  LPopup,
  LCircleMarker
} from 'vue2-leaflet'

export default {
  name: 'LocationMap',
  props: {
    searchTerms: Array
  },
  components: {
    LMap,
    LTileLayer,
    LPopup,
    LCircleMarker
  },
  data () {
    return {
      markrMaxHeight: 100,
      zoom: 6,
      minZoom: 6,
      center: [55.3781, -3.4360],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      circle: {
        radius: 6,
        color: 'red',
        fillColor: 'green',
        weight: 1
      }
    }
  },
  methods: {
    latLng: function (lat, lng) {
      return L.latLng(lat, lng)
    },
    filterTerms: function () {
      var termsFiltered = []
      for (var i = 0; i < this.searchTerms.terms.length; i++) {
        if (termsFiltered.indexOf(this.searchTerms.terms[i].term) === -1) {
          termsFiltered.push(this.searchTerms.terms[i].term)
        }
      }
      return termsFiltered
    }
  },
  computed: {
    filterMarkersByCat () {
      return this.listings.filter(function (listings) {
        return this.categories.includes(listings.localAuthorities)
      }, this)
    }
  }
}
</script>

<template>
<div>
  <v-menu>
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-bind="attrs"
            v-on="{ ...tooltip, ...menu }"
            medium
            class="mt-4"
            color="primary"
          >
            Date Range
            <v-icon right color="white">mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <span>Select a date range</span>
      </v-tooltip>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in dateOptions"
        :key="index"
        @click="emitPreSetDates(item.value)"
      >
        <v-list-item-title>{{ item.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
  <v-menu
    z-index="9999"
    v-if="dateFilter === 5"
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="dates"
    transition="scale-transition"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        v-bind="attrs"
        v-on="on"
        class="mt-4"
      >
        Select a Range
        <v-icon right>mdi-calendar-range</v-icon>
      </v-btn>
    </template>
    <v-date-picker v-model="dates" scrollable range>
      <v-spacer></v-spacer>
      <v-btn text color="dark" @click="menu = false">Cancel</v-btn>
      <v-btn :disabled="dates.length <= 1" text color="dark" @click="emitCustomDates()">OK</v-btn>
    </v-date-picker>
  </v-menu>
  <v-row>
    <v-col cols="3">
      <v-card class="mt-0">
        <v-toolbar color="indigo" dark>
          <v-toolbar-title>Category</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-text-field
            v-model="searchPostCode"
            hide-details
            append-icon="mdi-magnify"
            single-line
            label="Search by postcode"
            clearable
            @change="emitPostCode"
          ></v-text-field> -->
          <LocationSearch @locationEmitted="emitLocation" @locationRemoved="removeLocation"/>
        </v-toolbar>
        <div v-for="(cat, index) in categories" :key="index" class="pa-2">
          <v-checkbox v-model="category" :for="cat" :label="cat.tagName" :value="cat.tagName" :color="cat.colour" hide-details></v-checkbox>
        </div>
      </v-card>
    </v-col>
    <v-col cols="9">
      <l-map :zoom="zoom" :minZoom="minZoom" :center="center" style="height: 900px; width: 100%">
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <l-circle-marker v-for="(listing, index) in filterMarkersByCat" :key="index" :class="listing.category" :lat-lng="latLng(listing.latitude, listing.longitude)" :radius="circle.radius" :color="listing.categoryColour" :fillColor="listing.categoryColour" :weight="circle.weight">
          <l-popup>
            <v-list dense>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ listing.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ listing.category }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list dense>
              <v-list-item>
                <span class="font-weight-bold">Postcode: </span> {{ listing.postcode }}
              </v-list-item>
              <v-list-item>
                <span class="font-weight-bold">Area: </span> {{ listing.area }}
              </v-list-item>
              <v-list-item>
                <span class="font-weight-bold">City:</span> {{ listing.city }}
              </v-list-item>
              <v-list-item>
                <span class="font-weight-bold">Views:</span> {{ listing.numberOfViews }}
              </v-list-item>
            </v-list>
          </l-popup>
        </l-circle-marker>
      </l-map>
    </v-col>
  </v-row>
</div>
</template>

<script>
import L from 'leaflet'
import LocationSearch from '@/views/dashboard/component/LocationSearch'
import {
  LMap,
  LTileLayer,
  LCircleMarker,
  LPopup
} from 'vue2-leaflet'

export default {
  name: 'LocationMap',
  props: {
    listings: Array,
    categories: Array
  },
  components: {
    LMap,
    LTileLayer,
    LCircleMarker,
    LPopup,
    LocationSearch
  },
  data () {
    return {
      searchPostCode: '',
      dates: [],
      menu: false,
      dateFilter: 4,
      dateOptions: [
        { value: 1, text: 'Today' },
        { value: 2, text: 'Yesterday' },
        { value: 3, text: 'Last 7 Days' },
        { value: 4, text: 'Last 28 Days' },
        { value: 5, text: 'Custom Date Range' }
      ],
      selectAll: false,
      category: [],
      markrMaxHeight: 100,
      zoom: 6,
      minZoom: 6,
      iconSize: [15, 15],
      center: [55.3781, -3.4360],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      circle: {
        radius: 6,
        color: 'red',
        fillColor: 'green',
        weight: 1
      }
    }
  },
  methods: {
    latLng: function (lat, lng) {
      return L.latLng(lat, lng)
    },
    emitPreSetDates (value) {
      this.dateFilter = value
      this.dates = []
      let filterObject = {}
      if (this.dateFilter !== 5) {
        filterObject = {
          filterType: this.dateFilter
        }
        this.$emit('dateFilter', filterObject)
      }
    },
    emitCustomDates () {
      this.$refs.menu.save(this.dates)
      this.menu = false
      let filterObject = {}
      if (this.dateFilter === 5) {
        this.dates.sort(function (a, b) {
          a = a.split('/').reverse().join('')
          b = b.split('/').reverse().join('')
          return a > b ? 1 : a < b ? -1 : 0
        })
        const startDate = this.dates[0]
        const endDate = this.dates[1]
        if (startDate && endDate) {
          filterObject = {
            filterType: this.dateFilter,
            startDate: startDate,
            endDate: endDate
          }
          this.$emit('dateFilter', filterObject)
        } else {
          // eslint-disable-next-line
          console.error('No Dates Found.')
        }
      }
    },
    emitLocation (location) {
      if (location) {
        this.$emit('searchLocation', location)
      }
    },
    removeLocation () {
      this.$emit('removeLocation')
    }
  },
  computed: {
    filterMarkersByCat () {
      return this.listings.filter(function (listings) {
        return this.category.includes(listings.category)
      }, this)
    }
  }
}
</script>

<template>
  <v-autocomplete
    v-model="select"
    :error-messages="errorMessages"
    :loading="loading"
    :items="items"
    :search-input.sync="search"
    :outlined="outlined"
    cache-items
    class="mx-4"
    hide-no-data
    hide-details
    label="Enter a location/postcode"
    item-text="name"
    item-value="name"
    return-object
    clearable
    @input="emitLocation"
  ></v-autocomplete>
</template>

<script>

import getLocations from '@/Services/Analytics/AdvancedLocationSearch'
import { debounce } from 'debounce'
export default {
  props: { outlined: { type: Boolean, default: false }, errorMessages: { type: Array, default: function () { return [] } } },
  data () {
    return {
      loading: false,
      items: [],
      search: null,
      select: null
    }
  },
  watch: {
    search (val) {
      val && val !== this.select && this.querySelections(val)
    }
  },
  methods: {
    querySelections: debounce(async function (v) {
      this.loading = true
      await getLocations.getLocations(v)
        .then((res) => {
          if (res.status === 200) {
            this.items = res.data
            this.loading = false
          } else {
            // eslint-disable-next-line
            console.error(res.data)
            this.loading = false
          }
        })
    }, 300),
    emitLocation (location) {
      if (location) {
        this.$emit('fullLocationEmit', location)
        if (location.districtID) {
          this.$emit('locationEmitted', location.districtID)
        }
      } else {
        this.$emit('locationRemoved')
      }
    }
  }
}
</script>
